(function() {
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselRangeInput', {
            controller: aflModalCarouselRangeInputController,
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-range-input/afl-modal-carousel-range-input.html',
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                fieldName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflModalCarouselRangeInputController() {
        var $ctrl = this;

        $ctrl.isVisible = function isVisible() {
            return typeof $ctrl.visible !== "undefined" ?  $ctrl.visible : true;
        };

    }
})();
